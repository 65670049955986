.terms{
    width: 80vw;
    margin: 0 auto 80px auto;
}
.terms h1{
    font-family: "Lora",serif;
}
.terms p {
    text-align: justify;
   
    font-weight: 400;

}
.terms h3 {
    margin: 40px 0 0;
    font-family: "Lora",serif;
}
.terms h4 {
    margin: 40px 0 0;
    font-family: "Lora",serif;
}
@media screen and (max-width: 600px){
    .terms p {
        font-size: .9em;
    }
    .terms h3 {
        font-size: 1.2em;
        margin: 30px 0 0;
    }
    .terms h4 {
        font-size: 1.1em;
        margin: 30px 0 0;
    }
    .terms{
        width: 90vw;
    }
    .terms h1{
        font-size: 1.5em;
    }
}