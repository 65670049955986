.navbar {
 
  z-index: 99;
  top: 0;
  width: 100%;
  /* height: 69px; */
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
}

.option{
  color: black;
}

.logo {
  /* padding: 15px 0; */
  height: 80px;
  margin-top: 15px;
  margin-left:50px;
}
.img{
  width: 120px;
  height: 80px;
}