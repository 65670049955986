.hpdiv7{
    display: flex;
    flex-direction: column;
    /* margin-left: 10%;
    margin-right: 10%; */
    margin-bottom: 5vw;
   
}
.testimonials{
    margin-bottom: 50px;      
}
.content{
    display: flex;
    justify-content: space-evenly;
    padding-left: 10%;
    padding-right: 10%;

}
.content img{
    width: 3vw;
    height: 2vw;
}

.review{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.dp {
    position: relative;
    width: 13vw;
    height: 13vw;
   
    border-radius: 50%;
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    
    /* align-items: center;
    align-content: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    font-size: 2vw; */

  }
  #ratings{
      width: 120px;
	  height: 17px;
	  margin-right: 2vw;
  }
  @media (max-width:600px) {
      .content{
          flex-direction: column;
          
      }
      .review{
          width: 100%;
      }
      .dp{
          width: 30vw;
          height: 30vw;
          align-self: center;
          margin: 25px;
      }
      .hpdiv7 h1{
        text-align: center;
        font-size: 6vw;
      }
      
  }
