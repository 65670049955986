.howitworks{
    justify-content: center;
    
}
.how-it-works{
    font-size: 2.3vw;
    padding-right: 36vw;
	text-transform: uppercase;
}
.h-r2{
    border: 1px solid #FFB40A ;
    width:50% ; 
	height: 0px;
	float:left;
    margin-left:-105px;
    background-color:#EBAF27;
}
.howitworks img{
    width: 100%;
}
@media (max-width:600px){
	#img-hw {
	display: none !important;
	}
    #img-hw1 {
        padding-bottom: 5vw;
    }
	.h-r2{
        width: 45%;
        margin-left: 0;
    }
	.t-2{
        padding-right:25vw;
        margin-right: 5vw;
    }
	.how-it-works{
        font-size: 4vw;
        margin-right: 0;
        padding-right: 0;
    }
}

@media (min-width:1530px){
	.h-r2{
        width: 41%;
        margin-left: 0;
    }
	.how-it-works{
		font-size: 2.3vw;
		padding-right: 36vw;
		text-transform: uppercase;
	}
}

@media (min-width:600px){
	#img-hw1 {
	display: none !important;
	}
   
}

	
