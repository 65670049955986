.hpdiv6{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #F7F6F6;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 50px;
}