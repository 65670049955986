.card-list {
    padding-top: 28vh;
	padding-bottom: 8vh;
    margin: 0 1vw;
    display: grid;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

@media (max-width:500px){
	.card-list{
		justify-content: center;
	}
}
