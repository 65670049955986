.hpdivone{
    /* padding-top: 17vh; */
    display: flex;
    justify-content: space-between;

    height: 65vh;
	margin: 0 0 1vh 0;
	margin-left: 0;
	width: 96vw;
	padding: 14vh 2vw 0 2vw;
	
	/* background: white; */
	flex-direction: row;
    align-items: center;
	
}
#h2-partner-1{
    margin-bottom: 0px;
    position: relative;
    font-weight: 800;
    

}
#h2-partner-2{
    position: relative;
    font-weight: 800;
    margin-top: 0;
}
#span-partner-1{
    color:#EBAF27;
}
#span-partner-2{
    color: #EBAF27;
    text-transform: none;
}
.video-landing {
	align-self: center;
	height: auto;
	width: 40vw;
	min-width: 600px;
	border-radius: 30px;
	padding: 0;
	margin-right: 3vw;
}
.video-landing:focus {
	outline: none;
}
.texts-div1{
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
}
@media (max-width: 600px) {
    
    .texts-div1{
        margin: 0 auto;
    }
    .hpdivone{
        text-align: center;
    }
    .get-qt-bt{
        margin: 0 auto;
      
    }
    
    .opaque-div{
        background-color: #fff;
        opacity: 0.4;
        width: 100%;
        height: 55%;
        position: absolute;
        /* margin-bottom: 17vw; */
        margin-top: -7vh;
        height: 52%;
        position: absolute;
        margin-top: -10vh;
        margin-bottom: 17vw;
    }

}
@media(min-width:1000px){
    .texts-div1{
        font-size: x-large;
    }
}
@media screen and (max-width: 1300px) {
	
	.video-landing {
		align-self: center;
		width: 36vw;
		min-width: 530px;
		height: auto;
		margin-right: 3vw;
	}
}

@media screen and (max-width: 970px) {
	
	.video-landing {
		position: absolute;
        top: 14vh;
        width: 100vw;
        min-height: 65vh;
        min-width: 100vw;
        max-height: 78vh;
        object-fit: cover;
        left: 0;
        border-radius: 0;
        z-index: -1;
        
	}
    .hpdivone{
        justify-content: center;
        /* background: rgba(15,15,15,.514); */
		padding: 14vh 2vw 0 2vw;
    }
	#h2-partner-1{
        color: white;
    }
    #h2-partner-2{
        color: white;
    }
    #quot-button{
        background-color: #EBAF27;
    }
}