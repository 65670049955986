
.image-card-detail{
    width: -moz-fit-content;
    width: fit-content;
    height: 400px;
    max-height: 600px;
    width: 35vw;
}
.card-cardDetail {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 40vw;
    margin: 0px auto;
    text-align: center;
	border: 5px solid;
    padding: 25px 0;
    font-family: arial;
    background-color: mintcream;
  }

.cards{
	padding-top: 28vh;
	padding-bottom: 8vh;
}

@media (max-width:600px){
	.card-cardDetail{
		 max-width: 70vw;
		width: 70vw;
	}
	.image-card-detail{
		max-width: 65vw;
		width: 65vw;
		height: 400px;
    	max-height: 600px;
	}
	.cards{
	padding-top: 20vh;
	padding-bottom: 5vh;
}
}
