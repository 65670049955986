.custom-button {
  width: fit-content;
  min-width: 125px;
  height: 50px;
  margin: 5px;
  letter-spacing: 1px;
  padding: 0 35px 0 35px;
  font-size: 20px;
  background-color: #FFB40A;
  color: white;
  text-transform: uppercase;
  text-align: center;
	 font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer;
   border-radius: 10px;
  
}
.btn-subheader{
	align-items: center;
	display: flex;
	padding: 0 10px;
}
.custom-button:hover {
  color: black;
	border: 2px solid black;
}
.custom-button.green {
  background-color: #01A29A;
  color: white;
	font-size: 20px;
    font-weight: 700;
}
.custom-button.green:hover {
  color: black;
	border: 2px solid black;

}

@media only screen and (max-width: 768px) {
  .custom-button {
    font-size: 12px;
    padding: 0 15px 0 15px;
  }
}
