@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
	overflow-x: hidden !important;
	max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App p{
    text-decoration: none;
}
.whatsapp-float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	text-align:center;
    font-size:30px;
	
    z-index:100;
}

.whatsapp-my-float{
	margin-top:16px;
}
.navbar {
 
  z-index: 99;
  top: 0;
  width: 100%;
  /* height: 69px; */
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
}

.option{
  color: black;
}

.logo {
  /* padding: 15px 0; */
  height: 80px;
  margin-top: 15px;
  margin-left:50px;
}
.img{
  width: 120px;
  height: 80px;
}
.custom-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 125px;
  height: 50px;
  margin: 5px;
  letter-spacing: 1px;
  padding: 0 35px 0 35px;
  font-size: 20px;
  background-color: #FFB40A;
  color: white;
  text-transform: uppercase;
  text-align: center;
	 font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer;
   border-radius: 10px;
  
}
.btn-subheader{
	align-items: center;
	display: flex;
	padding: 0 10px;
}
.custom-button:hover {
  color: black;
	border: 2px solid black;
}
.custom-button.green {
  background-color: #01A29A;
  color: white;
	font-size: 20px;
    font-weight: 700;
}
.custom-button.green:hover {
  color: black;
	border: 2px solid black;

}

@media only screen and (max-width: 768px) {
  .custom-button {
    font-size: 12px;
    padding: 0 15px 0 15px;
  }
}


li {
  padding: 18px 10px;
  padding-bottom: 0px;
}
.active__option {
  border-bottom: 4px solid #01A29A;

}

.option{
  font-size: 1.4em;
  text-decoration: none;
}

.option:hover {
  border-bottom: 4px solid #01A29A;
  cursor: pointer;
  transition-duration: 200ms;
}
@media (max-width: 900px) {
  .navbar ul {
    flex-flow: column nowrap;
    background-color: #01A29A;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    width: -webkit-fill-available;
  }

  .option:hover{
    border-bottom: #FFB40A 4px solid;
  } 

   .active__option{
    border-bottom: #FFB40A 4px solid;
  }
}

@media (min-width: 901px) {
  .btn-header{
    display: none;
  }
}


.header{
    display: flex;
    position: fixed;
    background-color: white;
    z-index: 99;
    width: 100%;
    box-shadow: 0 0 10px rgb(20 20 20 / 14%);
    /* flex-direction: column; */
  }
  .subheader-two{
   display: flex;
   flex-direction: column;
   width: 100%;
  }
  .subheader-two-upper{
    display: flex;
	background-color:#323232;
	color:white;
    justify-content: space-between;
    margin-left: 235px;
    font-size: 13px;
	  	height:50px;
  }
  .subheader-two-lower{
    display: flex;
  }
#path{
	background-color:#323232;
	 width: 0;
     height: 0;
     border-bottom: 50px solid white;
     border-right: 100px solid transparent;
}
  .logo {
    /* padding: 15px 0;*/
    height: 80px;
    margin-top: 15px;
    margin-left:5vw !important;
    margin-bottom: 8px;
  }
  .logo-img{
    width: 120px;
    height: 80px;
    
  }
  @media (max-width: 1310px) {
    .subheader-two-upper{
      font-size: 15px;
    }
  }
  @media (max-width: 1020px) {
    .subheader-two-upper{
      font-size: 13px;
    }
  }
  @media screen and (max-width: 900px) {
    .subheader-two-upper{
      margin-top: 80px;
      margin-left: 0;
      font-size: 13px;
    }
    .logo{
      margin-left: 0px important;
    }
  }
  #subheader-two-upper-right{
    margin-left: 15px;
  }
  @media (max-width: 900px) {
    #subheader-two-upper-right{
      display: none;
    }
    .subheader-two-upper{
    /* font-size: 10px; */
    display: none;
    }
    

  }
  @media (max-width: 900px) {
    .btn-subheader{
      display: none;
     }
	  .header{
		  height: 14vh;
	  }
  }

.hpdivone{
    /* padding-top: 17vh; */
    display: flex;
    justify-content: space-between;

    height: 65vh;
	margin: 0 0 1vh 0;
	margin-left: 0;
	width: 96vw;
	padding: 14vh 2vw 0 2vw;
	
	/* background: white; */
	flex-direction: row;
    align-items: center;
	
}
#h2-partner-1{
    margin-bottom: 0px;
    position: relative;
    font-weight: 800;
    

}
#h2-partner-2{
    position: relative;
    font-weight: 800;
    margin-top: 0;
}
#span-partner-1{
    color:#EBAF27;
}
#span-partner-2{
    color: #EBAF27;
    text-transform: none;
}
.video-landing {
	align-self: center;
	height: auto;
	width: 40vw;
	min-width: 600px;
	border-radius: 30px;
	padding: 0;
	margin-right: 3vw;
}
.video-landing:focus {
	outline: none;
}
.texts-div1{
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
}
@media (max-width: 600px) {
    
    .texts-div1{
        margin: 0 auto;
    }
    .hpdivone{
        text-align: center;
    }
    .get-qt-bt{
        margin: 0 auto;
      
    }
    
    .opaque-div{
        background-color: #fff;
        opacity: 0.4;
        width: 100%;
        height: 55%;
        position: absolute;
        /* margin-bottom: 17vw; */
        margin-top: -7vh;
        height: 52%;
        position: absolute;
        margin-top: -10vh;
        margin-bottom: 17vw;
    }

}
@media(min-width:1000px){
    .texts-div1{
        font-size: x-large;
    }
}
@media screen and (max-width: 1300px) {
	
	.video-landing {
		align-self: center;
		width: 36vw;
		min-width: 530px;
		height: auto;
		margin-right: 3vw;
	}
}

@media screen and (max-width: 970px) {
	
	.video-landing {
		position: absolute;
        top: 14vh;
        width: 100vw;
        min-height: 65vh;
        min-width: 100vw;
        max-height: 78vh;
        object-fit: cover;
        left: 0;
        border-radius: 0;
        z-index: -1;
        
	}
    .hpdivone{
        justify-content: center;
        /* background: rgba(15,15,15,.514); */
		padding: 14vh 2vw 0 2vw;
    }
	#h2-partner-1{
        color: white;
    }
    #h2-partner-2{
        color: white;
    }
    #quot-button{
        background-color: #EBAF27;
    }
}
.hpdivtwo{
    display: flex;
    justify-content: space-between;

}
.hpdivtwo-left{
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
}
.hpdivtwo-right {
    
    width: 30%;
}
.hpdivtwo-right img{
    
width: 100%;
height: 100%;
object-fit: cover;
}
.h-r{
     border: 1px solid #FFB40A ;
         width:44vw ;
         height:0px ;
        margin-left:-79px;
        float:left ;
         background-color:#EBAF27;
}

.imgCont {
    position: relative;
    width: 13vw;
    height: 13vw;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    
    align-items: center;
    align-content: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    font-size: 2vh;

  }
  .hpdivtwo-left-para{
    align-items:center;
    padding:  0 6vw 13vh 16vw;
  }
  .hpdivtwo-left-para p{
     font-size:  20px;
        text-align: right;
  }
  .imgCont img{
      width: 45%;
      height: 45%;
      margin: 12px 0 0 0;
  }
.image-section{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
	font-weight:600;
}
@media (max-width: 600px) {
    .hpdivtwo-right img{
        display: none;
    }
    .hpdivtwo-left{
        width: 100%;
		margin-top: 10vh;
    }
    .hpdivtwo-left-para{
        padding: 0 2vw 5vw 2vw;
    }
    .hpdivtwo-left-para p{
        text-align: center;
    }
    .image-section{
        width: 100%;
        
    }
    .imgCont{
        width: 33vw;
        height: 33vw;
        margin: 2vw;
    }
    .h-r{
 		width: 46%;
        margin-left: 0;
    }
    .hpdivtwo{
        flex-direction: column;
		margin: 0 0 15px 0;
    }
    .t-1{
        padding-right: 28vw;
        margin-right: 5vw;
    }
    .why-modpro h1{
        font-size: 4vw;
    }
}

.hpdiv3{
    display: flex;
    /* margin-top: 150px; */
    margin-bottom: 7vw;
    justify-content: space-between;
}


.hpdiv3-left{
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #F7F6F6;
    padding: 5vh 10vw ;
    justify-content: space-evenly;
}
.hpdiv3-left p{
	font-size:18px;
	margin:0;
	
}
.hpdiv3-right{
    max-width: 158.5px;
    overflow: hidden;
    margin-top: 10vh;
}
.hpdiv3-right img{
    max-width: none;
    max-width: initial;
}


@media (max-width: 600px) {
    .hpdiv3-left{
        width: 100%;
        margin-left: 2%;
        margin-right: 2%;
    }
    .hpdiv3-right{
        display: none;
    }
}
.card-container {
    display: flex;
    flex-direction: column;
    width: 15vw;
    height: 30vw;
    /* background-color: #95dada; */
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    border-radius: 15px;
    padding: 30px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
    margin-bottom: 2vh;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .card-container:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  /* .title{
    font-size: 1vw;
  } */
  .image{
    width: 8vw;
  }
  .card-container h2{
    text-align: center;
	  text-transform: uppercase;
  }

@media(max-width:800px){
  .image{
    max-height: 130px;
  }
}
@media (max-width: 1040px) {
  .card-container{
    width: 25vw;
    height: 50vw;
  }
  .image{
    width: 14vw;
     
   }
   .title{
     font-size: 3vw;
   }
   .para{
     font-size: 2.2vw;
   }
}
@media (min-width: 1170px) {
  .card-container{
    height: 28vw;
  }
  
}
@media (min-width: 1250px) {
  .card-container{
    height: 25vw;
  }
  
}
@media (max-width: 750px) {
  .card-container{
    width: 30vw;
    height: 60vw;
  }
  .title{
    font-size: 4vw;
  }
  .para{
    font-size: 3.2vw;
  }
}
@media (max-width: 450px) {
  .card-container{
    width: 200px;
    height: 300px;
  }
  .title{
    font-size: 5vw;
  }
  .para{
    font-size: 4.2vw;
  }

}
.hpdiv6{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #F7F6F6;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 50px;
}
.hpdiv7{
    display: flex;
    flex-direction: column;
    /* margin-left: 10%;
    margin-right: 10%; */
    margin-bottom: 5vw;
   
}
.testimonials{
    margin-bottom: 50px;      
}
.content{
    display: flex;
    justify-content: space-evenly;
    padding-left: 10%;
    padding-right: 10%;

}
.content img{
    width: 3vw;
    height: 2vw;
}

.review{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.dp {
    position: relative;
    width: 13vw;
    height: 13vw;
   
    border-radius: 50%;
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    
    /* align-items: center;
    align-content: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    font-size: 2vw; */

  }
  #ratings{
      width: 120px;
	  height: 17px;
	  margin-right: 2vw;
  }
  @media (max-width:600px) {
      .content{
          flex-direction: column;
          
      }
      .review{
          width: 100%;
      }
      .dp{
          width: 30vw;
          height: 30vw;
          align-self: center;
          margin: 25px;
      }
      .hpdiv7 h1{
        text-align: center;
        font-size: 6vw;
      }
      
  }

.sponsors{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-evenly;
    margin-bottom: 6vw;
}
.sponsor img{
    width: 205px;
    
    height: 130px;
    display: block;
    max-width: 100%;
   
}
.sponsor{
    margin: 3vw 5vw;
}
.associated-brands{
    font-size: 2.3vw;
    padding-right: 30vw;
	text-transform: uppercase;
}
.h-r3{
    border: 1px solid #FFB40A ;
    width:50% ; 
	height: 0px;
	float:left;
    margin-left:-105px;
    background-color:#EBAF27;
}
@media screen and (max-width: 900px) {
    .sponsor {
        margin: 5vw 5vw;
        
    }
    .sponsor img{
        padding: 5vw 0.1vw;
    }
}
@media screen and (max-width: 600px) {
    .h-r3{
        width: 35%;
        margin-left: 0;
    }
    .t-3{
        padding-right: 22vw;
        margin-right: 5vw;
    }
    .associated-brands{
        font-size: 4vw;
        margin-right: 0;
        padding-right: 0;
    }
}
@media (min-width:1530px){
    .h-r3{
        width: 41%;
        margin-left: 0;
    }
    .associated-brands{
        font-size: 2.3vw;
        padding-right: 30vw;
        text-transform: uppercase;
    }
}
.howitworks{
    justify-content: center;
    
}
.how-it-works{
    font-size: 2.3vw;
    padding-right: 36vw;
	text-transform: uppercase;
}
.h-r2{
    border: 1px solid #FFB40A ;
    width:50% ; 
	height: 0px;
	float:left;
    margin-left:-105px;
    background-color:#EBAF27;
}
.howitworks img{
    width: 100%;
}
@media (max-width:600px){
	#img-hw {
	display: none !important;
	}
    #img-hw1 {
        padding-bottom: 5vw;
    }
	.h-r2{
        width: 45%;
        margin-left: 0;
    }
	.t-2{
        padding-right:25vw;
        margin-right: 5vw;
    }
	.how-it-works{
        font-size: 4vw;
        margin-right: 0;
        padding-right: 0;
    }
}

@media (min-width:1530px){
	.h-r2{
        width: 41%;
        margin-left: 0;
    }
	.how-it-works{
		font-size: 2.3vw;
		padding-right: 36vw;
		text-transform: uppercase;
	}
}

@media (min-width:600px){
	#img-hw1 {
	display: none !important;
	}
   
}

	

.footer {
    display: flex;
    color: white;
    background-color: #303030;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 5vh 5vw;
}

.footer h3{
    border-bottom: orange 1px solid;
    font-weight: bold;
}
.g-1{
    width: 65%;
    display: flex;
    justify-content: space-evenly;
}
.g-1 img{
    height: 60%;
    margin-left: 5vw;
    margin-top: 2vw;
    margin-right: 2vw;
}
.c-1{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
@media (max-width: 600px) {
    .footer h3{
        font-size: small;
    }
    .footer p{
        font-size: 13px;
    }
    .footer{
       padding: 5vh 10vw ;
        align-items: flex-start;
    }
    
    .c-1 img{
       
        width: 21vw;
        margin-bottom: 4vw;
        margin-top: 5vw;
    }
}
.rect-about{
    width: 20vw;
    height: 7vw;
    padding: 0.5vw;
   
    font-size: 1.7vw;
    text-align: center;
    justify-content: center;
    align-items: center;
   
    
}
.top-p{
    text-align: center;
    margin-left: 20vw;
    margin-right: 20vw;
}
.rect-about h4{
    font-weight: 400;
}
.circle-about {
    position: relative;
    width: 8vw;
    height: 8vw;
   
    border-radius: 50%;
    box-shadow: 0 0 0 3px whitesmoke;
    
    

  }
  .table{
      display: flex;
      flex-direction: column;
      height: 52vw;
      justify-content: space-evenly;
  }
  .row-about{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  }
.left{
    text-align: right;
}
.right{
    text-align: left;
}
.about-us{
    
    margin-bottom: 50px;
}
.text-above-about{
    padding-top: 155px;
}
@media (max-width: 600px) {
    .table{
        height: 90vw;
    }
    .rect-about{
        font-size:2.8vw;
        width: 32vw;
        height: 12vw;

    }
    .top-p{
        margin-left: 2vw;
        margin-right: 2vw;
    }
}
.about-div2{
    display: flex;
    align-items:center;
    justify-content: space-between;
   margin-top: 10vw;
    padding-right: 5vw;
    padding-left: 10vw; 
}
.about-div2-left{
    display: flex; 
    align-items: center;
 }
 .about-div2-right{
     width: 48%;
     display: flex;
	 padding: 30px;
     flex-direction: column;
     background-color: #F7F6F6;

 }
 .about-div2-right p{
     font-size: 1.5vw;
	 display: flex;
 }

.about-div2-right p:before {
    content:"\f111";
    color: rgb(0, 124, 128);
	font-family:"FontAwesome";
	padding: 0 10px;
}

  
@media (max-width: 600px) {
    .about-div2{
       flex-direction: column;
     
    }
    .about-div2-right{
        width: 100%;
        height: 55vw;
        justify-content: space-evenly;
        padding-left: 2vw;
    }
    .about-div2-right p{
        font-size: 3vw;
    }
}
.about-div3{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding-right: 10vw;
    padding-left: 5vw; 
}
.about-div3-right{
    display: flex; 
    align-items: center;
 }
 .about-div3-left{
     width: 48%;
     display: flex;
	 padding: 30px;
     flex-direction: column;
     background-color: #F7F6F6;
 }
 .about-div3-left p{
     font-size: 1.5vw;
	 display: flex;
 }

.about-div3-left p:before {
   	content:"\f111";
    color: rgb(0, 124, 128);
	font-family:"FontAwesome";
	padding: 0 10px;
}

@media (max-width: 600px) {
    .about-div3{
       flex-direction: column-reverse;
      
    }
    .about-div3-left{
        width: 100%;
        height: 55vw;
        justify-content: space-evenly;
        padding-left: 2vw;
    }
    .about-div3-left p{
        font-size: 3vw;
    }
}
  
.privacy-policy{
    width: 80vw;
    margin: 0 auto 80px auto;
}
.privacy-policy p {
    text-align: justify;
}
.privacy-policy h3{
    margin: 40px 0 0;
    font-family: "Lora",serif;

}
.privacy-policy h1{
    font-family: "Lora",serif;

}

@media screen and (max-width: 600px){
    .privacy-policy {
        width: 90vw;
    }

    .privacy-policy h1 {
        
        font-size: 1.5em;   
    }
    .privacy-policy li, .privacy-policy p {
        font-size: .9em;
    }
    .privacy-policy h3 {
        font-size: 1.2em;
        margin: 30px 0 0;
    }
}
.terms{
    width: 80vw;
    margin: 0 auto 80px auto;
}
.terms h1{
    font-family: "Lora",serif;
}
.terms p {
    text-align: justify;
   
    font-weight: 400;

}
.terms h3 {
    margin: 40px 0 0;
    font-family: "Lora",serif;
}
.terms h4 {
    margin: 40px 0 0;
    font-family: "Lora",serif;
}
@media screen and (max-width: 600px){
    .terms p {
        font-size: .9em;
    }
    .terms h3 {
        font-size: 1.2em;
        margin: 30px 0 0;
    }
    .terms h4 {
        font-size: 1.1em;
        margin: 30px 0 0;
    }
    .terms{
        width: 90vw;
    }
    .terms h1{
        font-size: 1.5em;
    }
}
.contactus {
	background-color: #f5f5f5;
	padding: 100px 0px;
	text-align: center;
}

.contactus .contact__box {
	width: 90%;
	margin: auto;
	background-color: white;
	box-shadow: 10px 10px 5px #e6e4e4;
	border-radius: 20px;
}

.contactus{
	color: #01A29A;
	font-weight: 700;
	padding: 17vh 0 20px 0;
}
 .contact__box h1 {
	color: #FFB40A;
	font-weight: 700;
	padding: 30px 0 20px 0;
}

.contactus .contact__box .contact {
	display: flex;
}

.contactus .contact__box .contact .contact__left {
	border-right: 1px solid #FFB40A;
	width: 50%;
	margin-bottom: 50px;
}

.contactus .contact__box .contact .contact__left img {
	width: 30%;
	margin: 10px auto;
}

.contactus .contact__box .contact .contact__left .contact__detail {
	text-align: left;
	width: 70%;
	margin: 40px auto;
	margin-top: 80px;
}

.contactus .contact__box .contact .contact__left .contact__detail h5 {
	font-weight: 500;
}

.contactus .contact__box .contact .contact__left .contact__detail p {
	margin: 30px 0;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.contactus .contact__box .contact .contact__left .contact__detail p i {
	margin-right: 30px;
	font-weight: bold;
	color: #01A29A;
}

.contactus .contact__box .contact .contact__form {
	display: flex;
	flex-direction: column;
	text-align: left;
	align-items: center;
	width: 50%;
}

.contactus .contact__box .contact .contact__form label {
	display: flex;
	flex-direction: column;
	width: 75%;
	margin-bottom: 10px;
}

.contactus .contact__box .contact .contact__form label .input__label {
	margin: 0.5rem 0;
	font-weight: 600;
}

.contactus .contact__box .contact .contact__form label input {
	background-color: #f5f5f5;
	border: none;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.contactus .contact__box .contact .contact__form label textarea {
	background-color: #f5f5f5;
	border: none;
	padding: 10px;
	border-radius: 10px;
	height: 100px;
	margin-bottom: 25px;
}

.contactus .contact__box .contact .contact__form .submit {
	font-size: 18px;
	color: white;
	background-color: #01A29A;
	padding: 10px 20px;
	text-transform: uppercase;
	margin-bottom: 60px;
	border: none;
	font-weight: 400;
}

.contactus .contact__box .contact .contact__form .submit:hover {
	border: 1px solid #01A29A;
	background-color: transparent;
	color: #01A29A;
}

.contact a {
	text-decoration: none;
	color: black;
}

.contact a:hover {
	color: #FFB40A;
}

@media only screen and (max-width: 600px) {
	.contactus .contact__box .contact {
		flex-direction: column;
	}
	.contactus h1 {
		font-size: 20px!important;
	}
	.contactus .contact__box .contact .contact__left {
		width: 100%;
		border-bottom: 1px solid #FFB40A;
		border-right: none !important;
	}

	.contactus .contact__box .contact .contact__form {
		width: 100%;
	}
	.contactus .contact__box .contact .contact__left .contact__detail {
		text-align: left;
		width: 70%;
		margin: 40px auto 20px auto !important;
	}
	.contactus {
		padding: 17vh 0px 60px 0;
	}
	
}@media only screen and (max-width: 931px) {
	.ph-no{
		display: flex;
		flex-direction: column;
	}
}
.faqs {
	width: 80vw;
	margin: 0px auto 80px auto;
    padding-top: 21vh;
	display: flex;
	flex-direction: column;

}
.faqs h3 {
	font-family: 'Lora', serif;
	font-weight: 600;
	font-size: 1.2em;
	margin: 4px;
}

.faqs ul {
	margin: 0;
}

.faqs li {
	text-align: justify;
	font-weight: 500;
	padding: 0 0 0 10px;
	margin: 15px 0;
	font-size: 1em;
	color: #383838;
	font-style: italic;
	
}

.faqs .accordin__faqs {
	display: flex;
	justify-content: space-between;
}

.faqs .accordin__faqs .accordin-1 {
	width: 98%;
	margin: 0 1%;
}

.faqs .accordin__faqs .accordin-2 {
	width: 98%;
	margin: 0 1%;
}
.faqs .accord {
	background: #f5f5f5;
	margin-bottom: 10px !important;
}

.MuiAccordionSummary-expandIcon {
	color: #01A29A !important;
}

.MuiAccordionDetails-root {
	display: flex;
	padding: 0px 20px 8px 0px;
}

@media screen and (max-width: 1024px) {
	.faqs .accordin__faqs {
		flex-direction: column;
	}
}
@media screen and (max-width: 560px) {
	.faqs {
		width: 90vw;
	}

	.faqs p {
		font-size: 0.9em;
	}
}


.image-card-detail{
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: 400px;
    max-height: 600px;
    width: 35vw;
}
.card-cardDetail {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 40vw;
    margin: 0px auto;
    text-align: center;
	border: 5px solid;
    padding: 25px 0;
    font-family: arial;
    background-color: mintcream;
  }

.cards{
	padding-top: 28vh;
	padding-bottom: 8vh;
}

@media (max-width:600px){
	.card-cardDetail{
		 max-width: 70vw;
		width: 70vw;
	}
	.image-card-detail{
		max-width: 65vw;
		width: 65vw;
		height: 400px;
    	max-height: 600px;
	}
	.cards{
	padding-top: 20vh;
	padding-bottom: 5vh;
}
}

.card-list {
    padding-top: 28vh;
	padding-bottom: 8vh;
    margin: 0 1vw;
    display: grid;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

@media (max-width:500px){
	.card-list{
		justify-content: center;
	}
}

.card-container-gallery {
    display: flex;
    flex-direction: column;
    width: 20vw;
    background-color: mintcream;
    border: 1px solid black;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
    margin-bottom: 2vh;
  }
  
  .card-container-gallery:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  /* .title{
    font-size: 1vw;
  } */
  .product-image{
    max-height: 300px;
	  height:300px;
    
  }

/* @media(max-width:800px){
  .product-image{
    max-height: 130px;
	  height:130px;
  }
} */

@media (max-width: 500px){
	.card-container-gallery{
		width: 60vw;
		max-width:60vw;
		 border: 3px solid black;
	}
}
  

.comingsoon{
    display: flex;
    flex-direction: column;
    text-align: center;
}
/* .video-landing2 video{
    width: 50%;
    height: 50%;
    justify-self: center;
    
} */
.video-landing2{
    padding-top: 18vh;
    justify-self: center;
    margin: 30px auto;

}
@media screen and (max-width: 560px) {
   .video-landing2{
    width: 100%;
    height: 100%;
    margin: 115px 0;
   }
}
