.hpdiv3{
    display: flex;
    /* margin-top: 150px; */
    margin-bottom: 7vw;
    justify-content: space-between;
}


.hpdiv3-left{
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #F7F6F6;
    padding: 5vh 10vw ;
    justify-content: space-evenly;
}
.hpdiv3-left p{
	font-size:18px;
	margin:0;
	
}
.hpdiv3-right{
    max-width: 158.5px;
    overflow: hidden;
    margin-top: 10vh;
}
.hpdiv3-right img{
    max-width: initial;
}


@media (max-width: 600px) {
    .hpdiv3-left{
        width: 100%;
        margin-left: 2%;
        margin-right: 2%;
    }
    .hpdiv3-right{
        display: none;
    }
}