

.App p{
    text-decoration: none;
}
.whatsapp-float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	text-align:center;
    font-size:30px;
	
    z-index:100;
}

.whatsapp-my-float{
	margin-top:16px;
}