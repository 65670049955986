.card-container {
    display: flex;
    flex-direction: column;
    width: 15vw;
    height: 30vw;
    /* background-color: #95dada; */
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    border-radius: 15px;
    padding: 30px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
    margin-bottom: 2vh;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .card-container:hover {
    transform: scale(1.05);
  }
  /* .title{
    font-size: 1vw;
  } */
  .image{
    width: 8vw;
  }
  .card-container h2{
    text-align: center;
	  text-transform: uppercase;
  }

@media(max-width:800px){
  .image{
    max-height: 130px;
  }
}
@media (max-width: 1040px) {
  .card-container{
    width: 25vw;
    height: 50vw;
  }
  .image{
    width: 14vw;
     
   }
   .title{
     font-size: 3vw;
   }
   .para{
     font-size: 2.2vw;
   }
}
@media (min-width: 1170px) {
  .card-container{
    height: 28vw;
  }
  
}
@media (min-width: 1250px) {
  .card-container{
    height: 25vw;
  }
  
}
@media (max-width: 750px) {
  .card-container{
    width: 30vw;
    height: 60vw;
  }
  .title{
    font-size: 4vw;
  }
  .para{
    font-size: 3.2vw;
  }
}
@media (max-width: 450px) {
  .card-container{
    width: 200px;
    height: 300px;
  }
  .title{
    font-size: 5vw;
  }
  .para{
    font-size: 4.2vw;
  }

}