.hpdivtwo{
    display: flex;
    justify-content: space-between;

}
.hpdivtwo-left{
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
}
.hpdivtwo-right {
    
    width: 30%;
}
.hpdivtwo-right img{
    
width: 100%;
height: 100%;
object-fit: cover;
}
.h-r{
     border: 1px solid #FFB40A ;
         width:44vw ;
         height:0px ;
        margin-left:-79px;
        float:left ;
         background-color:#EBAF27;
}

.imgCont {
    position: relative;
    width: 13vw;
    height: 13vw;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: rgba(175, 184, 190, 0.63) 0px 7px 15px 1px;
    
    align-items: center;
    align-content: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    font-size: 2vh;

  }
  .hpdivtwo-left-para{
    align-items:center;
    padding:  0 6vw 13vh 16vw;
  }
  .hpdivtwo-left-para p{
     font-size:  20px;
        text-align: right;
  }
  .imgCont img{
      width: 45%;
      height: 45%;
      margin: 12px 0 0 0;
  }
.image-section{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
	font-weight:600;
}
@media (max-width: 600px) {
    .hpdivtwo-right img{
        display: none;
    }
    .hpdivtwo-left{
        width: 100%;
		margin-top: 10vh;
    }
    .hpdivtwo-left-para{
        padding: 0 2vw 5vw 2vw;
    }
    .hpdivtwo-left-para p{
        text-align: center;
    }
    .image-section{
        width: 100%;
        
    }
    .imgCont{
        width: 33vw;
        height: 33vw;
        margin: 2vw;
    }
    .h-r{
 		width: 46%;
        margin-left: 0;
    }
    .hpdivtwo{
        flex-direction: column;
		margin: 0 0 15px 0;
    }
    .t-1{
        padding-right: 28vw;
        margin-right: 5vw;
    }
    .why-modpro h1{
        font-size: 4vw;
    }
}
