.about-div3{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding-right: 10vw;
    padding-left: 5vw; 
}
.about-div3-right{
    display: flex; 
    align-items: center;
 }
 .about-div3-left{
     width: 48%;
     display: flex;
	 padding: 30px;
     flex-direction: column;
     background-color: #F7F6F6;
 }
 .about-div3-left p{
     font-size: 1.5vw;
	 display: flex;
 }

.about-div3-left p:before {
   	content:"\f111";
    color: rgb(0, 124, 128);
	font-family:"FontAwesome";
	padding: 0 10px;
}

@media (max-width: 600px) {
    .about-div3{
       flex-direction: column-reverse;
      
    }
    .about-div3-left{
        width: 100%;
        height: 55vw;
        justify-content: space-evenly;
        padding-left: 2vw;
    }
    .about-div3-left p{
        font-size: 3vw;
    }
}
  