.privacy-policy{
    width: 80vw;
    margin: 0 auto 80px auto;
}
.privacy-policy p {
    text-align: justify;
}
.privacy-policy h3{
    margin: 40px 0 0;
    font-family: "Lora",serif;

}
.privacy-policy h1{
    font-family: "Lora",serif;

}

@media screen and (max-width: 600px){
    .privacy-policy {
        width: 90vw;
    }

    .privacy-policy h1 {
        
        font-size: 1.5em;   
    }
    .privacy-policy li, .privacy-policy p {
        font-size: .9em;
    }
    .privacy-policy h3 {
        font-size: 1.2em;
        margin: 30px 0 0;
    }
}