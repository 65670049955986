.header{
    display: flex;
    position: fixed;
    background-color: white;
    z-index: 99;
    width: 100%;
    box-shadow: 0 0 10px rgb(20 20 20 / 14%);
    /* flex-direction: column; */
  }
  .subheader-two{
   display: flex;
   flex-direction: column;
   width: 100%;
  }
  .subheader-two-upper{
    display: flex;
	background-color:#323232;
	color:white;
    justify-content: space-between;
    margin-left: 235px;
    font-size: 13px;
	  	height:50px;
  }
  .subheader-two-lower{
    display: flex;
  }
#path{
	background-color:#323232;
	 width: 0;
     height: 0;
     border-bottom: 50px solid white;
     border-right: 100px solid transparent;
}
  .logo {
    /* padding: 15px 0;*/
    height: 80px;
    margin-top: 15px;
    margin-left:5vw !important;
    margin-bottom: 8px;
  }
  .logo-img{
    width: 120px;
    height: 80px;
    
  }
  @media (max-width: 1310px) {
    .subheader-two-upper{
      font-size: 15px;
    }
  }
  @media (max-width: 1020px) {
    .subheader-two-upper{
      font-size: 13px;
    }
  }
  @media screen and (max-width: 900px) {
    .subheader-two-upper{
      margin-top: 80px;
      margin-left: 0;
      font-size: 13px;
    }
    .logo{
      margin-left: 0px important;
    }
  }
  #subheader-two-upper-right{
    margin-left: 15px;
  }
  @media (max-width: 900px) {
    #subheader-two-upper-right{
      display: none;
    }
    .subheader-two-upper{
    /* font-size: 10px; */
    display: none;
    }
    

  }
  @media (max-width: 900px) {
    .btn-subheader{
      display: none;
     }
	  .header{
		  height: 14vh;
	  }
  }
