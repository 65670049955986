
li {
  padding: 18px 10px;
  padding-bottom: 0px;
}
.active__option {
  border-bottom: 4px solid #01A29A;

}

.option{
  font-size: 1.4em;
  text-decoration: none;
}

.option:hover {
  border-bottom: 4px solid #01A29A;
  cursor: pointer;
  transition-duration: 200ms;
}
@media (max-width: 900px) {
  .navbar ul {
    flex-flow: column nowrap;
    background-color: #01A29A;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    width: -webkit-fill-available;
  }

  .option:hover{
    border-bottom: #FFB40A 4px solid;
  } 

   .active__option{
    border-bottom: #FFB40A 4px solid;
  }
}

@media (min-width: 901px) {
  .btn-header{
    display: none;
  }
}

