.faqs {
	width: 80vw;
	margin: 0px auto 80px auto;
    padding-top: 21vh;
	display: flex;
	flex-direction: column;

}
.faqs h3 {
	font-family: 'Lora', serif;
	font-weight: 600;
	font-size: 1.2em;
	margin: 4px;
}

.faqs ul {
	margin: 0;
}

.faqs li {
	text-align: justify;
	font-weight: 500;
	padding: 0 0 0 10px;
	margin: 15px 0;
	font-size: 1em;
	color: #383838;
	font-style: italic;
	
}

.faqs .accordin__faqs {
	display: flex;
	justify-content: space-between;
}

.faqs .accordin__faqs .accordin-1 {
	width: 98%;
	margin: 0 1%;
}

.faqs .accordin__faqs .accordin-2 {
	width: 98%;
	margin: 0 1%;
}
.faqs .accord {
	background: #f5f5f5;
	margin-bottom: 10px !important;
}

.MuiAccordionSummary-expandIcon {
	color: #01A29A !important;
}

.MuiAccordionDetails-root {
	display: flex;
	padding: 0px 20px 8px 0px;
}

@media screen and (max-width: 1024px) {
	.faqs .accordin__faqs {
		flex-direction: column;
	}
}
@media screen and (max-width: 560px) {
	.faqs {
		width: 90vw;
	}

	.faqs p {
		font-size: 0.9em;
	}
}
