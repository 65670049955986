.rect-about{
    width: 20vw;
    height: 7vw;
    padding: 0.5vw;
   
    font-size: 1.7vw;
    text-align: center;
    justify-content: center;
    align-items: center;
   
    
}
.top-p{
    text-align: center;
    margin-left: 20vw;
    margin-right: 20vw;
}
.rect-about h4{
    font-weight: 400;
}
.circle-about {
    position: relative;
    width: 8vw;
    height: 8vw;
   
    border-radius: 50%;
    box-shadow: 0 0 0 3px whitesmoke;
    
    

  }
  .table{
      display: flex;
      flex-direction: column;
      height: 52vw;
      justify-content: space-evenly;
  }
  .row-about{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  }
.left{
    text-align: right;
}
.right{
    text-align: left;
}
.about-us{
    
    margin-bottom: 50px;
}
.text-above-about{
    padding-top: 155px;
}
@media (max-width: 600px) {
    .table{
        height: 90vw;
    }
    .rect-about{
        font-size:2.8vw;
        width: 32vw;
        height: 12vw;

    }
    .top-p{
        margin-left: 2vw;
        margin-right: 2vw;
    }
}