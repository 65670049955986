.about-div2{
    display: flex;
    align-items:center;
    justify-content: space-between;
   margin-top: 10vw;
    padding-right: 5vw;
    padding-left: 10vw; 
}
.about-div2-left{
    display: flex; 
    align-items: center;
 }
 .about-div2-right{
     width: 48%;
     display: flex;
	 padding: 30px;
     flex-direction: column;
     background-color: #F7F6F6;

 }
 .about-div2-right p{
     font-size: 1.5vw;
	 display: flex;
 }

.about-div2-right p:before {
    content:"\f111";
    color: rgb(0, 124, 128);
	font-family:"FontAwesome";
	padding: 0 10px;
}

  
@media (max-width: 600px) {
    .about-div2{
       flex-direction: column;
     
    }
    .about-div2-right{
        width: 100%;
        height: 55vw;
        justify-content: space-evenly;
        padding-left: 2vw;
    }
    .about-div2-right p{
        font-size: 3vw;
    }
}