.card-container-gallery {
    display: flex;
    flex-direction: column;
    width: 20vw;
    background-color: mintcream;
    border: 1px solid black;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
    margin-bottom: 2vh;
  }
  
  .card-container-gallery:hover {
    transform: scale(1.05);
  }
  /* .title{
    font-size: 1vw;
  } */
  .product-image{
    max-height: 300px;
	  height:300px;
    
  }

/* @media(max-width:800px){
  .product-image{
    max-height: 130px;
	  height:130px;
  }
} */

@media (max-width: 500px){
	.card-container-gallery{
		width: 60vw;
		max-width:60vw;
		 border: 3px solid black;
	}
}
  
