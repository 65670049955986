.comingsoon{
    display: flex;
    flex-direction: column;
    text-align: center;
}
/* .video-landing2 video{
    width: 50%;
    height: 50%;
    justify-self: center;
    
} */
.video-landing2{
    padding-top: 18vh;
    justify-self: center;
    margin: 30px auto;

}
@media screen and (max-width: 560px) {
   .video-landing2{
    width: 100%;
    height: 100%;
    margin: 115px 0;
   }
}