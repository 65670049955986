.sponsors{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    justify-content: space-evenly;
    margin-bottom: 6vw;
}
.sponsor img{
    width: 205px;
    
    height: 130px;
    display: block;
    max-width: 100%;
   
}
.sponsor{
    margin: 3vw 5vw;
}
.associated-brands{
    font-size: 2.3vw;
    padding-right: 30vw;
	text-transform: uppercase;
}
.h-r3{
    border: 1px solid #FFB40A ;
    width:50% ; 
	height: 0px;
	float:left;
    margin-left:-105px;
    background-color:#EBAF27;
}
@media screen and (max-width: 900px) {
    .sponsor {
        margin: 5vw 5vw;
        
    }
    .sponsor img{
        padding: 5vw 0.1vw;
    }
}
@media screen and (max-width: 600px) {
    .h-r3{
        width: 35%;
        margin-left: 0;
    }
    .t-3{
        padding-right: 22vw;
        margin-right: 5vw;
    }
    .associated-brands{
        font-size: 4vw;
        margin-right: 0;
        padding-right: 0;
    }
}
@media (min-width:1530px){
    .h-r3{
        width: 41%;
        margin-left: 0;
    }
    .associated-brands{
        font-size: 2.3vw;
        padding-right: 30vw;
        text-transform: uppercase;
    }
}